var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"header-title"},[_vm._v("Popovers")]),_c('p',{staticClass:"sub-header font-13"},[_vm._v(" Add small overlay content, like those found in iOS, to any element for housing light information. ")]),_c('div',{staticClass:"button-list"},[_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.top",value:(
                'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'
              ),expression:"\n                'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'\n              ",modifiers:{"top":true}}],attrs:{"variant":"light"}},[_vm._v("Popover on top")]),_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.bottom",value:(
                'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'
              ),expression:"\n                'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'\n              ",modifiers:{"bottom":true}}],attrs:{"variant":"light"}},[_vm._v("Popover on bottom")]),_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.right",value:(
                'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'
              ),expression:"\n                'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'\n              ",modifiers:{"right":true}}],attrs:{"variant":"light"}},[_vm._v("Popover on right")]),_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.left",value:(
                'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'
              ),expression:"\n                'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'\n              ",modifiers:{"left":true}}],attrs:{"variant":"light"}},[_vm._v("Popover on left")])],1)])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"header-title"},[_vm._v("Tooltips")]),_c('p',{staticClass:"sub-header font-13"},[_vm._v(" Hover over the links below to see tooltips: ")]),_c('div',{staticClass:"button-list"},[_c('b-button',{attrs:{"id":"tooltip-top","variant":"light"}},[_vm._v("Tooltip on top")]),_c('b-tooltip',{attrs:{"target":"tooltip-top"}},[_vm._v("Tooltip on top")]),_c('b-button',{attrs:{"id":"tooltip-button","variant":"light"}},[_vm._v("Tooltip on bottom")]),_c('b-tooltip',{attrs:{"target":"tooltip-button","placement":"bottom"}},[_vm._v("Tooltip on bottom")]),_c('b-button',{attrs:{"id":"tooltip-left-button","variant":"light"}},[_vm._v("Tooltip on left")]),_c('b-tooltip',{attrs:{"target":"tooltip-left-button","placement":"left"}},[_vm._v("Tooltip on left")]),_c('b-button',{attrs:{"id":"tooltip-right-button","variant":"light"}},[_vm._v("Tooltip on right")]),_c('b-tooltip',{attrs:{"target":"tooltip-right-button","placement":"right"}},[_vm._v("Tooltip on right")])],1)])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }